import React from 'react';
import { ApolloProvider } from '@apollo/client';

import LayoutWithoutCheckout from '../../components/layout/layoutwithoutcheckout';
import Seo from '../../components/layout/seo';
import InviteHandler from '../../components/checkout/invite-handler';
import client from '@/webapi/client';

const Discount = ({ location }) => {
  return (
    <ApolloProvider client={client}>
      <LayoutWithoutCheckout>
        <Seo title="Dance" />
        <InviteHandler location={location} />
      </LayoutWithoutCheckout>
    </ApolloProvider>
  );
};

export default Discount;
