import React, { useState } from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { navigate } from 'gatsby-plugin-react-intl';
import queryString from 'query-string';
import { useMutation } from '@apollo/client';

import { CHECKOUT_INIT } from '../../webapi/WebApi';
import { DANCE_AREA } from '../../config/constants';

const REDIRECT_URL = '/c';

const InviteChecker = ({ location }) => {
  const query = queryString.parse(location.search);
  const inviteCode = query.i ? String(query.i) : undefined;
  const coupon = query.c ? String(query.c) : undefined;
  const danceArea = query.area ? String(query.area) : DANCE_AREA.BERLIN;

  return (
    <InviteHandler
      inviteCode={inviteCode}
      coupon={coupon}
      danceArea={danceArea}
    />
  );
};

const InviteHandler = ({ inviteCode, coupon, danceArea }) => {
  const [checkoutInit, { loading, data }] = useMutation(CHECKOUT_INIT);
  const [createdCheckout, setCreatedCheckout] = useState(false); // only needed because of strictmode?

  if (typeof window === `undefined`) {
    // dont render while gatsby build
    return null;
  }
  localStorage.removeItem('checkoutId');

  if (!createdCheckout) {
    const checkoutInitInput = {
      inviteCode,
      coupon,
      danceArea,
      payload: JSON.stringify({}),
    };
    checkoutInit({ variables: { checkoutInitInput: checkoutInitInput } });
    setCreatedCheckout(true);
    return;
  }

  if (loading || !data) {
    return <InviteView />;
  }

  if (!data.checkoutInit) {
    localStorage.removeItem('checkoutId');
    navigate(REDIRECT_URL);
    return null;
  }

  if (data) {
    localStorage.setItem('checkoutId', data.checkoutInit.id);

    navigate(REDIRECT_URL);
    return null;
  }

  return <InviteView />;
};

export const InviteView = () => {
  return (
    <div className="invite-view">
      <p>
        <FormattedMessage
          id="invite.validating"
          defaultMessage="Validating Invite…"
        />
      </p>
    </div>
  );
};

export default InviteChecker;
